import search from '@/Scripts/search.js';
import Chart from 'chart.js/auto';
import { register } from 'swiper/element/bundle';
import posthog from 'posthog-js';

window.globalSearch = search;
window.headerSearch = search;

if (import.meta.env.VITE_APP_ENV === 'production') {
	posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, { api_host: import.meta.env.VITE_POSTHOG_API_HOST });
}

// Handle toggle price table behavior
window.priceTable = () => ({
	togglePriceTable(tableId) {
		const targetTable = document.querySelector(`#${tableId}`);
		const toggleButton = targetTable.previousElementSibling;

		if (targetTable.classList.contains('hidden')) {
			toggleButton.innerHTML = 'Hide Price History';
			toggleButton.classList.remove('bg-primary-500');
			toggleButton.classList.replace('btn-primary', 'btn-dark');
		} else {
			toggleButton.innerHTML = 'View Price History';
			toggleButton.classList.add('bg-primary-500');
			toggleButton.classList.replace('btn-dark', 'btn-primary');
		}

		targetTable.classList.toggle('hidden');
	}
});

// Init chart.js
window.chartJs = (ctxId, chartData) => {
	const el = document.querySelector(`#${ctxId}`);
	if (!el) return;
	const ctx = el.getContext('2d');

	new Chart(ctx, {
		type: 'line',
		data: {
			labels: chartData['labels'],
			datasets: chartData['datasets']
		},
		options: {
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						callback: function (value) {
							return '$' + value.toLocaleString();
						}
					}
				}
			},
			plugins: {
				legend: {
					display: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		}
	});
};

window.onload = () => {
	register();
};
